<template>
  <div class="MainView">
    <div class="logo">
      <img v-if="!img" src="@/assets/logo.png" alt="">
      <img v-else style="width: 300px" :src='img' alt="图片">
    </div>
    <div class="content">
      <div  class="greeting" v-if="env.VUE_APP_AGENT_ID === 'yangguangxiaoyuan'" v-bind:style="{ color: activeColor, 'font-weight': fontSize }" >
        <span>京师心育 阳光校园</span>
        <br>
        <span>中小学生心理健康教育服务平台
      </span>
      </div>
      <div class="greeting" v-else>
        <span>欢迎来到，{{env.VUE_APP_AGENT_STSTEM_NAME ? env.VUE_APP_AGENT_STSTEM_NAME + '平台' : titleword}}</span>
      </div>
      <div class="choose">
        <span>请选择你的登录方式</span>
      </div>
      <div class="main">
        <div class="login"  v-on:click="open_client('eduadmin')">
          <img src="@/assets/jiaoweiduan.png" alt="">
        </div>
        <div class="login" v-on:click= "open_client('school')">
          <img src="@/assets/jiaoshiduan.png" alt="">
        </div>
        <div class="login" v-on:click= "open_client('student')">
          <img src="@/assets/xueshengduan.png" alt="">
        </div>
      </div>
    </div>
    <div class="remark">
      <p>
        <a href="https://www.lvluoxinli.com/static/html/guide.html" target="_blank" style="color:#999999">用户服务指南</a>&nbsp;&nbsp;
        <a href="https://www.lvluoxinli.com/static/html/clause.html" target="_blank" style="color:#999999">使用条款和隐私政策</a>&nbsp;&nbsp;
        服务邮箱：lvluoxinli@dingtalk.com
      </p>
      <p>
        ©2020 北京心数矩阵科技有限公司版权所有&nbsp;&nbsp;
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color:#999999">京ICP备18051758号-2</a> &nbsp;&nbsp;
        <i class="jgwablogo"></i>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202008381" target="_blank" style="color:#999999">京公网安备11010202008381</a>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'MainView',
  data () {
    return {
      env: process.env,
      activeColor: 'green',
      fontSize: 700,
      img: null,
      titleword:'家校社协同心理关爱平台',
      qudao: ['ncmhc.lvluoxinli.com']
    }
  },
  mounted () {
    let url = window.location.host
    if (this.qudao.includes(url)) {
      this.educatorAdminLogin(url)
    }
  },
  methods: {
     async educatorAdminLogin(url) { // 登录
      let response = await axios.get(`https://api.lvluoxinli.com/nacos/v1/cs/configs?tenant=74a84264-72f5-448e-868d-c9fb00be9d25&dataId=${url}&group=qudao`)
      const res = response.data
      this.img = res.logo_url1
      this.titleword = res.qudao_name
    },
    open_client(c) {
      let doamin=this.env.VUE_APP_AGENT_domain || 'https://cz.lvluoxinli.com' ;
      let path= this.env.VUE_APP_AGENT_PATH || ''
      switch (c) {
        case 'eduadmin':
           window.location.href=`${doamin}${path}/xsjzEducationPc/index.html#/`
          break;
        case 'school':
          window.location.href=`${doamin}${path}/teacherPC20/index.html#/`
          break;
        case 'student':
          if (doamin == 'https://ncmhc.lvluoxinli.com') {
            window.location.href=`${doamin}${path}/keti/stuPC/index.html#/`
          } else {
            window.location.href=`${doamin}${path}/stuPC/index.html#/`
          }
          break;
        default:
          console.log('Unknown fruit');
      }  
     
    }
   }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.MainView {
  width: 100%;
  height: 100%;
  position: relative;
}
.MainView .remark{
  position: absolute;
  width: 100%;
  bottom: 15px;
  font-size:14px;
  color:rgba(153,153,153,1);
  line-height:14px;
  text-align: center;
}
.remark>p{
  padding-bottom: 15px;
  font-size: 14px!important;
  display: block;
  -webkit-margin-before: 0px;
  margin-block-start: 0px;
  -webkit-margin-after: 0px;
  margin-block-end: 0px;
  -webkit-margin-start: 0px;
  margin-inline-start: 0px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
}
.logo{
  padding-left: 40px;
  padding-top: 40px;
}
.logo img{
  width:177px;
  object-fit:contain;
}
.MainView .content{
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
}
.greeting{
  text-align: center;
  font-size: 32px;
}

.choose{
  font-size: 14px;
  text-align: center;
  padding-top: 14px;
  color: #999999;
}
.main{
  display: flex;
  padding-top: 40px;
  justify-content: center;
}
.login img{
  height: 320px;
  width: 230px;
  margin-left: 10px;
  margin-right: 10px;
  transition: all 0.4s;
  transform: translateY(0px);
  cursor: pointer;
}
.login img:hover {
  transform: translateY(-40px);
  box-shadow:0 7px 10px rgba(0, 0, 0, .20);
}
.jgwablogo{
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('../assets/jgwab.png');
  position: relative;
  top: 3px;
}
</style>
